.top {
  position: relative;
  margin-top: -20px;
}

.icon {
  position: absolute;
  top: -1px;
  right: 10px;
}

@media (max-width: 768px) {
  .icon {
    top: 8px;
    right: 30px;
  }
}

.icon svg {
  width: 20px;
  height: 20px;
}

.topDocument .title {
  padding-right: 30px;
  max-width: 1000px;
}
