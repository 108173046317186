.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.wrapperTransparent {
  background: transparent;
}

.loader {
  animation: rotate-center 1.6s ease-in-out infinite backwards;
}

.sticky {
  position: sticky;
  top: 50%;
  margin: 50px 0;
}

.stickyParent {
  display: block;
  text-align: center;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
