.wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 9px;
  align-items: center;
  font-size: 16px;
  font-family: var(--font_family-accent);
}

.wrapper input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 10;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #bbbec1;
  border-radius: 4px;
  transition: 0.3s;
}

.wrapper:hover input ~ .checkmark {
  border: 2px solid #616161;
}

.wrapper:focus input ~ .checkmark {
  border: 5px solid #616161;
}

.error {
  background-color: rgba(215, 44, 13, 0.05);
  border: 2px solid #d72c0d;
}

.wrapper input:disabled ~ .checkmark {
  border: 2px solid #d2d5d8;
}

.wrapper input:checked ~ .checkmark {
  background-color: #65adff;
  border: 0;
}

.wrapper input:checked ~ .error {
  background-color: #d72c0d;
  border: 0;
}

.wrapper input:checked:disabled ~ .checkmark {
  background-color: #bdc1cc;
}

.content {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
}

.disabledContent {
  color: #8c9196;
}

.errorContent {
  color: #c83506;
}

.noPadding {
  padding: 0;
}
