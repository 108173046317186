.content {
  display: flex;
  flex-direction: column;
  color: var(--gray_color-darken);
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.subtitle {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
}
