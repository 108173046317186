.searchValue {
  font-style: italic;
}

.errorContainer {
  display: block;
  padding: 0.25rem 0 0 1.7rem;
  line-height: 1.5rem;
}

.errorText {
  font-size: var(--font_size-small);
  font-family: var(--font_family-accent);
  color: var(--danger_color);
}

.clipboardInputContainer {
  padding-left: 1.2rem;
}

.searchInputContainer {
  flex: 1;
}

.searchWrapper {
  display: flex;
  flex: 1;
}

.modalActionsWrapper {
  margin-top: 4rem !important;
}

.pasteImageInputContainer,
.pasteImageInputContainer::placeholder {
  font-style: normal !important;
  font-family: var(--font_family-accent) !important;
}

.prefixContainer {
  margin-top: 0;
}
