/* -------------------------- common styles ------------------------------- */
.custom-main-nomad {
  --text_color: #252321;
  --base-font_color: #252321;
  --nomad_header-height: 94px;
  --nomad_bg-color: #eeebe6;

  color: var(--base-font_color);
}

.custom-main-nomad .custom-left-content {
  padding-top: 140px;
  color: #fafafa;
}

.custom-main-nomad .custom-headline {
  overflow: visible;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 1px;
}

.custom-main-nomad .custom-text,
.custom-main-nomad .custom-overview-map-list,
.custom-main-nomad .custom-overview-item-desc,
.custom-main-nomad .custom-listblock-desc,
.custom-main-nomad .custom-costs-item,
.custom-main-nomad .custom-overview-map-list-title,
.custom-main-nomad .custom-overview-item-title {
  font-style: normal;
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 27px;
}

.custom-main-nomad .custom-text {
  font-family: 'Apercu', sans-serif;
}

.custom-main-nomad .custom-right-subheadline-text {
  font-style: normal;
  font-size: 19px;
  font-family: 'Apercu', sans-serif;
  line-height: 27px;
}

.custom-main-nomad .custom-right-subheadline {
  padding-bottom: 2px;
}

.custom-main-nomad .custom-overview-item-desc,
.custom-main-nomad .custom-text,
.custom-main-nomad .custom-overview-map-list-item {
  color: #666;
}

.custom-main-nomad .custom-overview-item-title {
  font-weight: 700;
}

.custom-main-nomad .custom-right-headline-inner {
  text-transform: uppercase;
  font-size: 26px;
  font-family: 'Ubuntu', sans-serif;
}

.custom-main-nomad .custom-left-subheadline {
  margin-left: 54px;
  font-size: 23px;
  font-family: 'BadScript', sans-serif;
  line-height: 25px;
}

.custom-main-nomad .custom-right-headline,
.custom-main-nomad .custom-block,
.custom-main-nomad .custom-right-headline::before {
  background-color: var(--nomad_bg-color);
}

.custom-main-nomad .custom-right-header {
  padding: 0 38px;
  background-color: #252321;
  border-color: transparent;
}

@media (max-width: 768px) {
  .custom-main-nomad .custom-right-header {
    padding: 0 20px;
  }
}

.custom-main-nomad .custom-notification-badge {
  background: var(--branding_color);
}

.custom-main-nomad .custom-mobile-app-info-button {
  background: transparent;
}

.custom-main-nomad .custom-mobile-app-info-button svg {
  height: 19px;
}

/* landing page bottom-border */
.custom-main-nomad .custom-right::after {
  background-color: transparent;
}

.custom-main-nomad .custom-landing,
.custom-main-nomad .custom-right,
.custom-main-nomad .custom-left-img {
  background-color: var(--nomad_bg-color);
}

.custom-main-nomad .custom-left-traveldates,
.custom-main-nomad .custom-left-location {
  margin-left: 54px;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 21px;
}

.custom-main-nomad .custom-right-header-companyname {
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 17px;
  font-family: 'Univers', sans-serif;
  color: #fff;
}

.custom-main-nomad .custom-imagehandler-item {
  background-color: #dadada;
}

.custom-main-nomad .custom-imagehandler-item path {
  fill: #fff;
}

.custom-main-nomad .custom-overview-map {
  background-color: #dadada;
}

.custom-main-nomad .custom-left-subheadline-border {
  display: none !important;
}

.custom-main-nomad .custom-safari_overview-left .custom-left-subheadline,
.custom-main-nomad .custom-safari_overview-left .custom-headline-container {
  display: none;
}

.custom-main-nomad .custom-right-page-header {
  position: relative;
  width: 100%;
}

.custom-main-nomad .custom-landing-page-mobile-nav {
  display: flex;
  padding: 0 38px;
  background-color: var(--nomad_bg-color);
  border-bottom: none;
  box-shadow: 0 8px 6px -6px rgb(0 0 0 / 30%);
}

.custom-main-nomad .custom-landing-page-mobile-nav > * {
  margin-right: 48px;
}

.custom-main-nomad .custom-landing-page-mobile-nav > *:first-child {
  padding-left: 0;
}

.custom-main-nomad .custom-landing-page-mobile-nav > *:last-child {
  margin-right: 0;
  padding-right: 0;
}

.custom-main-nomad .custom-landing-page-mobile-nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: auto;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  font-family: 'Apercu', sans-serif;
  letter-spacing: 1.5px;
  color: var(--base-font_color);
}

.custom-main-nomad .custom-landing-page-mobile-nav-active-link {
  padding-top: 3px;
  border-bottom: 3px solid var(--branding_color);
}

.custom-main-nomad .custom-nav-notch {
  display: none;
}

.custom-main-nomad .custom-left-location-wrapper {
  position: relative;
  bottom: auto;
  display: block;
}

.custom-main-nomad .custom-left-location-icon {
  display: none;
}

.custom-main-nomad .custom-left-img::before {
  background: linear-gradient(180deg, rgba(62, 62, 64, 0.6) -8.14%, hsla(0, 0%, 76.9%, 0) 95.4%);
}

.custom-main-nomad .custom-left-img::after {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.custom-main-nomad .custom-button-type-secondary {
  color: #fff;
  background: var(--branding_color);
  border-color: var(--branding_color);
}

.custom-main-nomad .custom-button-type-secondary:hover {
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
}

/* -------------------------- guest portal styles ------------------------------- */

.custom-main-nomad .custom-add-new-guest-button:hover {
  color: #a7a7a7;
}

.custom-main-nomad .custom-subtitle-block span[data-text='true'] {
  display: inline-block;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 19px;
  font-family: 'Apercu', sans-serif;
  line-height: 20px;
  letter-spacing: 2px;
  color: #252321;
  border-bottom: 1.5px solid #cfcfcf;
}

.custom-main-nomad .custom-guest_forms-left,
.custom-main-nomad .custom-safari_manifest-left,
.custom-main-nomad .custom-travel_wallet-left,
.custom-main-nomad .custom-relocations-left {
  width: 100%;
}

.custom-main-nomad .custom-landing-wrapper-guest_forms .custom-right-content,
.custom-main-nomad .custom-landing-wrapper-safari_manifest .custom-right-content,
.custom-main-nomad .custom-landing-wrapper-travel_wallet .custom-right-content,
.custom-main-nomad .custom-landing-wrapper-relocations .custom-right-content {
  margin: 0 auto;
  margin-top: 72px !important;
  width: 100%;
  max-width: 800px;
}

.custom-main-nomad .custom-guest_forms-right .custom-left-wave-pattern,
.custom-main-nomad .custom-safari_manifest-right .custom-left-wave-pattern,
.custom-main-nomad .custom-travel_wallet-right .custom-left-wave-pattern,
.custom-main-nomad .custom-relocations-right .custom-left-wave-pattern {
  display: block;
  background-color: var(--nomad_bg-color);
}

.custom-main-guest-domain.custom-main-nomad .custom-right-header-user {
  color: #fff;
}

.custom-main-guest-domain.custom-main-nomad .custom-right-header-username {
  font-weight: normal;
  font-style: normal;
  font-size: 1.2rem;
  font-family: 'Apercu', sans-serif;
  line-height: 1;
  color: #fff;
}

.custom-main-guest-domain.custom-main-nomad .custom-right-header-user-menu-list-label {
  font-family: 'Apercu', sans-serif;
}

.landing-theme-nomad:not(.landing-guest-builder) .custom-modal-content {
  background-color: #eeebe6;
}

.landing-theme-nomad:not(.landing-guest-builder) .custom-modal-title {
  font-family: 'Apercu', sans-serif;
}

.landing-theme-nomad .custom-guest_forms .custom-button,
.landing-theme-nomad:not(.landing-guest-builder) .custom-modal-content .custom-button {
  font-weight: 700;
  font-size: 15px;
  font-family: 'Apercu', sans-serif;
}

.landing-theme-nomad .custom-guest_forms .custom-button-type-default,
.landing-theme-nomad:not(.landing-guest-builder) .custom-modal-content .custom-button-type-default {
  background-color: #eeebe6;
}

.landing-theme-nomad .custom-guest_forms .custom-button-type-default:hover,
.landing-theme-nomad:not(.landing-guest-builder) .custom-modal-content .custom-button-type-default:hover {
  color: #a7a7a7;
}

/* -------------------------- TABLET; DESKTOP ------------------------------- */
@media (min-width: 769px) {
  .custom-main-nomad .custom-left-content {
    padding-left: 50px;
  }

  .custom-main-nomad .custom-right-headline::before {
    height: 0;
  }

  .custom-main-nomad .custom-landing-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .custom-main-nomad .custom-landing {
    width: 50%;
    min-height: var(--leftSideHeight);
  }

  .custom-main-nomad .custom-landing-wrapper-safari_map .custom-overview-map,
  .custom-main-nomad .custom-landing-wrapper-safari_map .mapContainerClassName {
    height: 600px !important;
  }

  .custom-main-nomad .custom-safari_map-left,
  .custom-main-nomad .custom-cover_page {
    display: none !important;
  }

  .custom-main-nomad .custom-cover_page-left,
  .custom-main-nomad .custom-landing-wrapper-safari_map,
  .custom-main-nomad .custom-landing-wrapper-safari_costs,
  .custom-main-nomad .custom-safari_costs-left,
  .custom-main-nomad .custom-landing-wrapper-area,
  .custom-main-nomad .custom-area-left {
    width: 100%;
  }

  .custom-main-nomad .custom-landing-wrapper-area .custom-right-content,
  .custom-main-nomad .custom-landing-wrapper-safari_costs .custom-right-content {
    margin: 0 auto;
    margin-top: 72px !important;
    max-width: 800px;
  }

  .custom-main-nomad {
    --leftSideHeight: calc(100vh - var(--nomad_header-height)) !important;
  }

  .custom-main-nomad.custom-main-preview {
    --leftSideHeight: calc(var(--modalHeight) - var(--nomad_header-height)) !important;
  }

  .custom-main-guest-domain.custom-main-nomad {
    --leftSideHeight: calc(100vh - var(--nomad_header-height) - var(--gpNavHeaderHeight)) !important;
  }

  .custom-section-nomad .custom-main-nomad {
    /* top header 65px - buttons container  25px - bottom margin 66px - landing sticky header 94px */
    --leftSideHeight: calc(100vh - 65px - 25px - 66px - var(--nomad_header-height)) !important;
  }

  .override .custom-section-nomad .custom-main-nomad {
    /* top header 65px -buttons container  25px - bottom margin 66px - landing sticky header 94px - override header 65px */
    --leftSideHeight: calc(100vh - 65px - 25px - 66px - var(--nomad_header-height) - 65px) !important;
  }

  .custom-section-nomad .custom-main-nomad.withActions {
    /* top header 65px -buttons container  69px - bottom margin 66px - landing sticky header 94px */
    --leftSideHeight: calc(100vh - 65px - 69px - 66px - var(--nomad_header-height)) !important;
  }

  .override .custom-section-nomad .custom-main-nomad.withActions {
    /* top header 65px -buttons container  69px - bottom margin 66px - landing sticky header 94px - override header 65px */
    --leftSideHeight: calc(100vh - 65px - 69px - 66px - var(--nomad_header-height) - 65px) !important;
  }

  .custom-main-nomad .custom-left {
    top: var(--nomad_header-height); /* landing sticky header 94px */
  }

  .custom-main-nomad .custom-area-right .custom-left-wave-pattern,
  .custom-main-nomad .custom-safari_costs-right .custom-left-wave-pattern {
    display: block;
    background-color: var(--nomad_bg-color);
  }
}

/* -------------------------- MOBILE ------------------------------- */
@media (max-width: 768px) {
  .custom-main-nomad .custom-right-header-companyname {
    font-size: 22px;
  }

  .custom-main-nomad .custom-safari_map-left,
  .custom-main-nomad .custom-cover_page-right,
  .custom-main-nomad .custom-safari_overview-left {
    display: none !important;
  }

  .custom-main-nomad .custom-cover_page {
    padding-bottom: 0;
  }

  .custom-main-nomad .custom-headline {
    padding-bottom: 0;
  }

  .custom-main-nomad .custom-left-content {
    padding: 20px;
  }

  .custom-main-nomad .custom-left-subheadline {
    margin-left: 25px;
  }

  .custom-main-nomad .custom-left-traveldates,
  .custom-main-nomad .custom-left-location {
    margin-left: 25px;
  }

  .custom-main-nomad ~ div .custom-mobile-app-info {
    font-family: 'Ubuntu', sans-serif;
  }
}

/* -------------------------- SVG stuff ------------------------------- */

.custom-main-nomad .custom-right-header-icon-share path {
  fill: #fff;
}

.custom-main-nomad .custom-right-header-icon-share svg > *:nth-child(3) {
  stroke: #fff;
}

.custom-main-nomad .custom-right-header-icon-print path {
  fill: #fff;
}

.custom-main-nomad .custom-right-header-icon-phone path {
  stroke: #fff;
}

.custom-main-nomad .custom-right-header-icon-invite path {
  stroke: #fff;
}

.custom-main-nomad .custom-right-alert > svg > path {
  stroke: #fff;
}

.custom-main-nomad .custom-right-header-icon-print path:nth-child(2) {
  stroke: #fff;
  stroke-width: 0.3px;
}
