.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-family: var(--font_family-accent);
  line-height: 130%;
  letter-spacing: 0;
  border-radius: 4px;
}

.alert_warning {
  color: #1624c2;
  background-color: #ad75b41f;
}

.alert_danger {
  color: #c83506;
  background-color: #eee;
}

.alert_info {
  color: #212121;
  background: rgba(101, 173, 255, 0.1);
}

.alertContainer {
  display: flex;
  flex-direction: column;
}
