.gm-bundled-control .gmnoprint {
  top: 46px !important;
  left: 20px !important;
  height: auto !important;
}

.gm-bundled-control .gmnoprint div {
  margin: 0 !important;
  width: 20px !important;
  height: auto !important;
}

.gm-control-active {
  width: 20px !important;
  height: 20px !important;
}

.gm-control-active img {
  width: auto !important;
  height: 14px !important;
}

.gmnoprint .gm-style-mtc button {
  height: 30px !important;
  font-size: 12px !important;
}
