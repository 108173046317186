.text {
  font-size: var(--font_size-base);
  color: var(--base-font_color);
}

.italic {
  font-style: italic;
}

.text-warning {
  color: var(--warning_color);
}

.text-success {
  color: var(--success_color);
}

.text-danger {
  color: var(--danger_color);
}

.text-disabled {
  opacity: 0.5;
}

.text-small {
  font-size: var(--font_size-small);
}

.text-medium {
  font-size: var(--font_size-table);
}

.text-regular {
  font-size: var(--font_size-default);
}

.text-default {
  font-size: 1.8rem;
}

.text-large {
  font-size: var(--font_size-large);
}

.text-accent {
  font-family: var(--font_family-accent);
}

.text-hoefler {
  font-family: var(--font_family-serif);
}

.text-brandon {
  font-family: var(--font_family-sans-serif);
}

.text-gray {
  color: var(--input_placeholder_color);
}

.text-color-gray-600 {
  color: var(--color_grey600);
}

.text-color-gray-500 {
  color: var(--color_grey500);
}

.marginBottom {
  display: inline-block;
  margin-bottom: 1rem;
}
