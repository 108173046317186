.warning {
  margin-top: 1.8rem;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-size: 1.6rem;
  font-family: var(--font_family-accent);
  line-height: 1.8rem;
  letter-spacing: 0.02rem;
  color: #df3802;
}
