.inputWrapper {
  position: relative;
}

.inputWrapperPrimary {
  margin-bottom: 1.4rem;
}

.inputWrapper input {
  padding-right: 16.6rem;
}

.placeholder {
  position: absolute;
  top: 0;
  right: 1.4rem;
  display: inline-flex;
  align-items: center;
  height: 4rem;
}

.icon {
  margin-left: 0.5rem;
}

.icon svg {
  width: 2.1rem;
  height: 2.1rem;
  fill: var(--input_placeholder_color);
  stroke: var(--input_placeholder_color);
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
