.empty {
  font-family: var(--font_family-sans-serif);
}

.emptyTitle {
  padding-bottom: 5px;
  text-align: center;
  font-weight: 450;
  font-size: 2rem;
}

.emptyText {
  margin-right: -5px;
  margin-left: -5px;
  text-align: center;
  font-size: 1.6rem;
}

.emptyText-mobile {
  display: none;
  margin-bottom: 25px;
  padding-bottom: 25px;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid var(--disabled_color);
}

.changedDateAlert div {
  font-weight: 400;
  color: var(--gray_color-darken);
}

@media (max-width: 768px) {
  .emptyTitle {
    font-size: 20px;
  }

  .emptyText {
    display: none;
  }

  .emptyText-mobile {
    display: block;
  }
}
