.view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.9rem 0;
}

.infoBlock {
  margin-top: 12px;
}
