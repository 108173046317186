.dropper * {
  font-size: 1.4rem !important;
}

.dropper {
  box-sizing: border-box;
  padding: 2rem;
  border: 1px dashed var(--input_border_color);
  outline: none;
}

.dropper-light {
  box-sizing: border-box;
  padding: 4.4rem;
  border: 1px solid var(--input_border_color);
  border-radius: 5px;
  outline: none;
}

.dropper-light .placeholder {
  font-size: 2.6rem;
}

.dropper.active {
  border-color: var(--input_border_color-focus);
}

.head {
  vertical-align: top;
  margin-bottom: 1.5rem;
}

.error {
  color: var(--danger_color);
}
