.container {
  position: absolute;
  bottom: 5%;
}

.name {
  display: -webkit-box;
  overflow: hidden !important;
  max-width: 15rem;
  max-height: 7rem;
  font-size: 1.3rem;
  font-family: var(--font_family-accent);
  line-height: 1.7rem;
  color: white;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
