:root {
  --body_bg: #f8f8f8;
  --text_color: #3f3c43;
  --text_color-critical: #d72c0d;
  --link_color: #7aa5e9;
  --gray_color: #a7a7a7;
  --gray_color-darken: #3f3c43;
  --primary_color: #65adff;
  --primary_color-saturated: #4995ec;
  --primary_color-lighten: #c1deff;
  --primary_color-darken: #82aad0;
  --primary_color_monochrome: #212121;
  --secondary_color: #ead6e2;
  --secondary_color-lighten: #f7eff3;
  --secondary_color-darken: #d8cad4;
  --danger_color: #df3802;
  --danger_color-saturated: #c83506;
  --danger_color-lighten: #f2af9a;
  --danger_color-darken: #ca3622;
  --success_color: #059669;
  --success_color-saturated: #06845d;
  --success_color-lighten: #99cea4;
  --success_color-darken: #2e9142;
  --warning_color: #f0d600;
  --warning_color-saturated: #ddc503;
  --warning_color-lighten: #f9ef99;
  --warning_color-darken: #dbca31;
  --accent_color: #ebb7a7;
  --accent_color-saturated: #d99b87;
  --accent_color-lighten: #f7e2dc;
  --accent_color-darken: #d9b4aa;
  --secondary2_color: #ad75b4;
  --secondary2_color-saturated: #9750a0;
  --secondary2_color-lighten: #c49cc9;
  --secondary2_color-darken: #976b9f;
  --black_color: #3e3e40;
  --black_color-lighten: #707074;
  --black_color-darken: #181819;
  --facebook_color: #2d7cbf;
  --facebook_color-lighten: #5fa1d9;
  --facebook_color-darken: #1e5481;
  --twitter_color: #33aed0;
  --twitter_color-lighten: #71c7de;
  --twitter_color-darken: #227c94;
  --info_color: #1890ff;
  --disabled_default_color: #fff;
  --disabled_color: #e0e0e0;
  --disabled_text_color: #8c9196;
  --additional_text_color: #616161;
  --branding_color: #c99048;
  --color_grey900: #212121;
  --color_grey700: #616161;
  --color_grey600: #757575;
  --color_grey500: #9e9e9e;
  --color_grey400: #bdbdbd;
  --color_grey300: #e0e0e0;
  --color_grey200: #eee;
  --color_grey100: #f5f5f5;
  --color_grey50: #fafafa;
  --color_base-brand-blue: #0781b5;
  --color_base-brand-darkblue: #1624c2;
  --color_base-brand-green: #00841c;
  --color_base-brand-green-hover: #016817;
  --color_base-brand-lightblue: #65adff;
  --color_base-brand-lightpink: #ead6e2;
  --color_base-brand-pale: #ebb7a7;
  --color_base-brand-pink: #ec559f;
  --color_base-brand-pink-hover: #cf307e;
  --color_base-brand-purple: #ad75b4;
  --color_base-brand-red: #df3802;
  --color_base-brand-yellow: #f0d600;
  --color_base-brand-yellow-hover: #ddc503;
  --color_grey-text-default: #3f3c43;

  /* Fonts */
  --base-font_color: var(--text_color);
  --font_family-serif: 'Hoefler', 'Georgia', 'Times New Roman', times, serif;
  --font_family-sans-serif: 'Brandon', -apple-system, system-ui, segoe ui, ubuntu, 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  --font_family-monospace: 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
  --font_family-mulish: 'Mulish', -apple-system, system-ui, segoe ui, ubuntu, 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif;
  --font_family-base: var(--font_family-serif);
  --font_family-accent: var(--font_family-sans-serif);
  --font_size-h1: 6.4rem;
  --font_size-h2: 5.5rem;
  --font_size-h3: 4rem;
  --font_size-h4: 3.2rem;
  --font_size-h5: 3.5rem;
  --font_size-large: 2.3rem;
  --font_size-base: 1.7rem;
  --font_size-default: 1.6rem;
  --font_size-table: 1.4rem;
  --font_size-small: 1.2rem;
  --hr_color: #dfdfdf;

  /* form adapters */
  --input_color: var(--text_color);
  --input_border_color: #d0d0d0;
  --input_border_color-focus: var(--primary_color);
  --input_placeholder_color: #c4c4c4;

  /* dashboard nav */

  --nav_width: 19.7rem;

  /* base media breakpoints  */

  /* 320px — 480px: Mobile devices.
  481px — 800px: iPads, Tablets.
  800px — 1024px: Small screens, laptops.
  1025px — 1200px: Desktops, large screens.
  1201px and more — Extra large screens, TV. */

  /* timing functions */
  --animation-spring: linear(
    0 0%,
    0 1.8%,
    0.01 3.6%,
    0.03 6.35%,
    0.07 9.1%,
    0.13 11.4%,
    0.19 13.4%,
    0.27 15%,
    0.34 16.1%,
    0.54 18.35%,
    0.66 20.6%,
    0.72 22.4%,
    0.77 24.6%,
    0.81 27.3%,
    0.85 30.4%,
    0.88 35.1%,
    0.92 40.6%,
    0.94 47.2%,
    0.96 55%,
    0.98 64%,
    0.99 74.4%,
    1 86.4%,
    1 100%
  );

  /* OLD */

  --input_border_color-danger: #e2656b;
  --input_background_color-danger: #f5c6cb;
  --input-secondary: rgba(0, 0, 0, 0.4);
  --input-secondary-lighten: #d9d9d9;
}
