.wrapper {
  width: 100%;
  min-width: 0;
  font-family: var(--font_family-accent);
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.inputWrapper label {
  position: absolute;
  left: 1rem;
  pointer-events: none;
  font-size: 1.6rem;
  line-height: 0.1rem;
  color: #9e9e9e;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, 2.2rem);
  transform-origin: top left;
}

.inputWrapper input {
  padding: 1.6rem 1rem 0;
  height: 4.4rem;
  font-size: 1.6rem;
  line-height: 1rem;
  color: #212121;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.inputWrapper:not(:global(.animationDisabled)):focus-within label,
.inputWrapper .filled {
  font-size: 1.1rem;
  transform: translate(0, 1.3rem);
}

.errorBorder input {
  border: 1px solid #d72c0d;
}

.inputWrapper input:disabled {
  color: #9e9e9e;
  background-color: #f2f2f2;
}

.inputWrapper:not(.errorBorder) input:hover {
  border-color: #b3b3b3;
}

.errorMessage {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 1.3rem;
  font-family: var(--font_family-accent);
  line-height: 1.3rem;
  color: #d72c0d;
}

.helpText {
  margin-top: 0.4rem;
  padding-left: 1rem;
  font-size: 1.1rem;
  font-family: var(--font_family-accent);
  line-height: 1.8rem;
  letter-spacing: 0.65px;
  color: #424242;
}

.inputWrapper input:disabled:hover {
  border-color: #e0e0e0;
}

.inputContainer {
  width: 100%;
}
