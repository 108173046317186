.container {
  display: flex;
  flex-wrap: wrap;
  min-width: 23.5rem;
  column-gap: 12px;
}

.content {
  display: flex;
  align-items: center;
  margin-top: 4px;
  min-width: 100px;
  font-weight: 400;
  font-size: 12px;
  font-family: var(--font_family-accent);
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #9e9e9e;
}

.isValidContent {
  color: #00841c;
}

.text {
  padding-left: 0.5rem;
}
