.formAction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.marginTop {
  margin-top: 2rem;
}

.marginBottom {
  margin-bottom: 2.4rem;
}

.formActionRight {
  justify-content: flex-end;
}

.formActionBetween {
  justify-content: space-between;
}

.formActionCenter {
  justify-content: center;
}

.formActionItem {
  margin-right: 1.6rem;
}

.formActionItem:last-child {
  margin-right: 0;
}
