.modal {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  max-width: initial;
}

.modalContent :global(.custom-dropper-text *) {
  font-size: 1.6rem !important;
  color: var(--gray_color-darken);
}

.modalContent :global(.custom-dropper-base-text) {
  font-weight: bold;
  font-size: 18px !important;
}

.modalContent :global(.custom-form-actions-wrapper) {
  margin: 0 -2.5rem -1.3rem -2.5rem;
  padding: 1.2rem 2.5rem 0 1.2rem;
  border-top: 1px solid #eee;
}

.modalContent :global(.custom-typography-form-title-wrapper) {
  margin-bottom: 0;
  border-bottom: 0;
}

.modal :global(.custom-modal-close-btn) {
  top: 3px;
  right: 10px;
}

.modalContent :global(.custom-form-actions-wrapper button) {
  padding: 0.9rem 1.6rem;
  min-width: 80px;
}

.modal :global(.custom-modal-close-icon svg) {
  width: 25px;
  height: 25px;
}

.modal :global(.custom-modal-content svg path) {
  stroke-width: 1px;
}

.modalContent {
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
}

.modalHeader {
  margin: 0 -2.5rem 2.5rem -2.5rem;
  padding: 0 2.5rem 1.5rem 2.5rem;
  font-weight: 600;
  font-size: 18px;
  font-family: var(--font_family-accent);
  border-bottom: 1px solid #eee;
}

.elementWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .modal {
    max-width: 90vw;
  }
}
