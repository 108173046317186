.container {
  position: relative;
  padding-top: 0.8rem;
}

.sliderWrap {
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.sliderWrap::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent; /* Chrome/Safari/Webkit */
}

.title {
  position: absolute;
  top: -1.9rem;
  font-weight: 500;
  font-style: normal;
  font-size: 1.6rem;
  font-family: var(--font_family-accent);
  line-height: 2.6rem;
  color: #c4c4c4;
}

.slider {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}

.descriptionName {
  max-width: 10rem;
}

.sliderItem {
  user-select: none;
  flex-shrink: 0;
  margin-right: 1rem;

  /* (container size - margins) / number of shown elements */
  width: calc((55rem - 3 * 1rem) / 4);

  /*
    do not remove outline style
    it fixes bug in safari https://app.asana.com/0/1200057905899238/1200118409488216/f
  */
  outline: 1rem solid white;
}

.sliderItem:last-child {
  margin-right: 0;
}

.dragOverlay {
  z-index: 99999 !important;
}

.left,
.right {
  position: absolute;
  top: calc(50% - 2.5rem);
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 5rem;
  height: 5rem;
  color: var(--text_color);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.right {
  left: calc(100% - 2.5rem);
  justify-content: flex-end;
  padding-right: 1rem;
}

.left {
  right: calc(100% - 2.5rem);
  justify-content: flex-start;
  padding-left: 1rem;
}

.left:hover,
.right:hover {
  opacity: 0.7;
}

.disabled {
  opacity: 0;
  visibility: hidden;
}
