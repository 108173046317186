.wrapper {
  margin-bottom: 1.8rem;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #eee;
}

.wrapper-noBorder-with-margin {
  margin-bottom: 1.8rem;
  padding-bottom: 1.2rem;
  border-bottom: none;
}

.wrapper-center {
  text-align: center;
}

.wrapper-center .main {
  letter-spacing: 1.49px;
}

.wrapper-noBorder {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.wrapper-clickable {
  cursor: pointer;
}

.wrapper-clickable:hover {
  color: var(--theme_color);
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.wrapper-hintBottom {
  margin-bottom: 1rem;
}

.formTitle {
  font-weight: 600;
  font-size: var(--font_size-large);
  font-family: var(--font_family-accent);
  word-break: break-word;
  letter-spacing: 0.02rem;
}

.formTitleWithAction,
.formTitleWithrightText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formTitle-normal {
  font-weight: normal;
}

.formTitle-small {
  font-size: 1.9rem;
}

.formTitle-large {
  font-size: 3.4rem;
}

.formTitle-forth {
  text-align: left;
}

.brackets {
  padding-left: 0.6rem;
  font-weight: 400;
}

.hint {
  line-height: 20px;
  letter-spacing: 0.2px;
}

.hint a {
  text-decoration: underline;
  color: var(--primary_color);
}

.hint a:hover {
  text-decoration: none;
}

.hintBottom {
  display: block;
  margin-bottom: 1.8rem;
}

.hintBottomCenter {
  text-align: center;
}
