.warningIcon {
  margin-top: 0.2rem;
  margin-right: 1.6rem;
}

.adjuster {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.4rem;
}

.caption {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.body {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  line-height: 23px;
}

.text {
  padding-right: 3rem;
}

.important {
  font-weight: 420;
  color: #f0d600;
}

.buttonWrapper {
  padding-left: 3rem;
}

.button {
  padding: 0;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.6rem;
  color: #fff;
  background: transparent;
  border: 0;
  outline: none;
}

.button:hover {
  opacity: 0.75;
  text-decoration: underline;
  color: #fff;
  background: transparent;
  border: 0;
}

.button:focus {
  text-decoration: underline;
  color: #fff;
  background: transparent;
}

.button:focus::after {
  border: 0;
}

@media (max-width: 768px) {
  .br {
    display: none;
  }

  .text {
    padding-right: 0;
  }
}
