.fieldArrayWrapper {
  position: relative;
}

.fieldArrayRemove {
  position: absolute;
  top: 50%;
  right: -3.8rem;
  opacity: 0;
  transition: all 0.2s ease;
}

.fieldArrayRemove-checkLabel {
  top: auto;
  bottom: 0;
}

.fieldArrayWrapper:hover .fieldArrayRemove {
  opacity: 1;
}
