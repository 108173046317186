.wrapper {
  display: flex;
  gap: 24px;
  font-family: var(--font_family-accent);
}

.vertical {
  flex-direction: column;
  gap: 6px;
}

.container {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: block;
  padding-left: 2.5rem;
  font-size: 1.6rem;
  line-height: 2rem;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  vertical-align: middle;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background-color: #fff;
  border: 2px solid #bbbec1;
  border-radius: 50%;
  aspect-ratio: 1;
}

.container input:disabled ~ .checkmark {
  border-color: #d2d5d8;
}

.container input:checked ~ .checkmark {
  border: 5px solid var(--theme_color);
}

.container input:checked:disabled ~ .checkmark {
  border: 5px solid #bdc1cc;
}

.container:hover input:not(:disabled):not(:checked) ~ .checkmark {
  border-color: #616161;
  transition: 0.3s;
}

.disabledContent {
  color: #8c9196;
}

@media (max-width: 768px) {
  .wrapper {
    flex-wrap: wrap;
    grid-gap: 12px;
  }
}
