.modalWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  transition: opacity 0.3s ease;
}

.margin {
  z-index: 1302;
  margin: auto;
}

@media (max-width: 1100px) {
  .margin-lg {
    padding: 0 40px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .margin {
    padding: 0 20px;
    width: 100%;
  }
}

.modal {
  z-index: 1302;
  margin: 2rem auto;
}

.header {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #d0d0d0;
}

.content {
  position: relative;
  box-sizing: border-box;
  padding: 3.6rem 3.9rem;
  width: 628px;
  height: 100%;
  background: #fff;
  border-radius: 14px;
}

.content-lmd {
  width: 680px;
}

.content-lg {
  width: 1028px;
}

@media (max-width: 768px) {
  .content-lmd,
  .content-lg {
    width: 100%;
  }
}

.content-sm {
  width: 475px;
}

@media (max-width: 768px) {
  .content {
    margin: 0;
    padding: 3.6rem 2rem;
    width: 100% !important;
  }
}

.content-auto {
  margin: 0 2rem;
  width: auto;
}

.noPaddingIframe {
  padding: 0;
}

@media (max-width: 768px) {
  .noPaddingIframe .modalClose {
    top: 0;
    margin: 2rem;
    color: black;
  }
}

.contentNoPadding {
  padding: 0;
}

@media (max-width: 1100px) {
  .content-lg {
    width: 100%;
  }
}

.content-lgm {
  width: 860px;
}

@media (max-width: 768px) {
  .content-lgm {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contentNoPadding .modalClose {
    top: -28px;
    margin: 0;
    color: #fff;
  }
}

.body {
  margin-bottom: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerSpaceBetween {
  justify-content: space-between;
}

.footerSecondary {
  box-sizing: border-box;
  margin: 20px -31px -37px;
  padding: 20px 31px;
  min-height: 59px;
  background: #f2f2f2;
  border-radius: 5px;
}

.modalClose {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px;
  padding: 0;
  line-height: 0;
  background: transparent;
  border: none;
  outline: none;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-property: all;
}

.modalClose:hover {
  color: #3f3c43;
  background-color: rgb(245 245 245);
}

.modalReturn {
  position: absolute;
  top: 0;
  z-index: 5;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 0;
  line-height: 0;
  background: transparent;
  border: none;
  outline: none;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1301;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.noPortalOpen {
  z-index: -9999999;
  opacity: 0;
}

.closeIcon svg {
  width: 2.4rem;
  height: 2.4rem;
  stroke-width: 1.5;
}
