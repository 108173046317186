.markerLabel {
  margin-top: 70px !important;
  min-width: 50px;
  font-weight: bold;
  font-size: 16px !important;
}

.tooltip {
  z-index: 2147483647;
  margin-left: -122px;
  padding: 10px;
  width: 162px;
  min-height: 35px;
  font-size: 10px;
  font-family: var(--font_family-accent);
  letter-spacing: 0.4px;
  background-color: #fff;
  border: 1px solid;
  border-radius: 5px;
  border-image-source: linear-gradient(0deg, #e1dfde -81.71%, rgba(225, 223, 222, 0) 100%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 4px;
  width: 0;
  height: 0;
  border-width: 0 10px 12px 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.tooltipText {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tooltipText strong {
  display: none;
  margin-right: 4px;
}

.tooltipText :global .overviewMapBottom {
  text-align: center;
}

.tooltipTextWithCount strong {
  display: inline-block;
  margin-right: 4px;
}
