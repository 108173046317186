.container {
  display: flex;
  flex-wrap: wrap;
}

.wrapper {
  flex-grow: 1;
  flex-basis: 50%;
}

.children {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: calc(100% - 3rem);
  text-overflow: ellipsis;
  white-space: nowrap;
}
