.item {
  position: absolute;
  top: 0;
  left: 0;
}

.containerWithoutSave::after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f2f2f2;
}

.placeholder svg {
  width: calc(28px + 3%);
  max-width: 20%;
  height: calc(23px + 3%);
}

.errorMessage {
  color: var(--danger_color);
}

.defaultSrc {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  min-width: 100%;
  max-width: initial;
  height: auto;
  min-height: 100%;
  max-height: 100%;
}
