.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: var(--font_size-base);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(208, 208, 208, 0.5);
}

.mainContent {
  position: relative;
  z-index: 1;
  padding: 2.6rem;
  width: 100%;
  max-width: 414px;
  background-color: #fff;
  border-radius: 5px;
}

.mainContent :global .link {
  font-size: 1.4rem;
}

.status,
.title {
  text-align: center;
  font-weight: 600;
  font-family: var(--font_family-accent);
}

.status {
  font-size: 3.8rem;
}

.footer {
  margin: 2.5rem -2.6rem -3.7rem;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.4rem;
  font-family: var(--font_family-accent);
  letter-spacing: 0.1px;
  background-color: #f2f2f2;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
