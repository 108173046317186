.content-sm,
.content-xs,
.content-auto {
  width: 570px;
}

.content-lmd {
  width: 680px;
}

.content-lg {
  width: 730px;
}

.content-xlg {
  width: 800px;
}

.content-xl {
  width: 90vw;
  max-width: 1210px;
}

.content-lg,
.content-xlg,
.content-sm,
.content-xl {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 90vh;
}

.content-auto,
.content-lmd {
  padding: 0;
  height: auto;
}

.content-xs {
  display: flex;
  flex-direction: column;
  padding: 0;
}

@media (max-width: 800px) {
  .content-lg,
  .content-sm,
  .content-xs,
  .content-lmd,
  .content-xl {
    width: 100%;
  }

  .content-sm {
    height: auto;
    max-height: 70vh;
  }
}

.closeButton {
  margin: 1.4rem;
  padding: 0.6rem;
}

.returnButton {
  margin: 1.4rem;
  padding: 0.6rem;
}

.closeButton:hover,
.returnButton:hover {
  background-color: var(--color_grey100);
  border-radius: 50%;
  transition: background-color 0.5s ease;
}

.header {
  display: flex;
  padding: 2rem;
  font-weight: 600;
  font-size: 1.8rem;
  font-family: var(--font_family-accent);
  border-bottom: 1px solid #eee;
}

.header-center {
  justify-content: center;
}

.body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem;
}

.withoutPadding {
  padding: 0;
}

.footerWrapper {
  bottom: 0;
  display: flex;
  gap: 8px;
  justify-content: end;
  padding: 12px 16px;
  width: 100%;
  border-top: 1px solid #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 8px;
  justify-content: end;
  padding: 12px 16px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-top: 1px solid #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  backdrop-filter: blur(2.5px);
}
