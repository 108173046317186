.wrapper {
  position: relative;
  display: inline-block;
  margin: 1rem;
  padding: 0.8rem 5rem 0.8rem 0.8rem;
  max-width: 30vw;
  text-align: left;
  color: var(--snackbar-color);
  background: var(--snackbar-bgColor);
  border-radius: 0.5rem;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.08);
}

@media (max-width: 768px) {
  .wrapper {
    min-width: 30rem;
  }
}

.commonRightPadding {
  padding-right: 0.8rem;
}

.fitContent {
  max-width: unset;
}

.wrapper.default {
  --snackbar-color: #fff;
  --snackbar-bgColor: #3e3e40;
}

.wrapper.error {
  --snackbar-color: #fff;

  /* --snackbar-bgColor: #ce4622; */
  --snackbar-bgColor: #d72c0d;
}

.wrapper.warning {
  --snackbar-color: #fff;
  --snackbar-bgColor: #212121;

  padding: 1.6rem 3.5rem 1.6rem 1.6rem;
  border-radius: 0.8rem;
}

.wrapper.success {
  --snackbar-color: #fff;
  --snackbar-bgColor: #212121;

  padding: 1.6rem 6.5rem 1.6rem 4.5rem;
  max-width: 40vw;
  border-radius: 0.8rem;
}

.icon {
  position: absolute;
  top: 1.3rem;
  right: 1.2rem;
  cursor: pointer;
  fill: var(--snackbar-color);
  stroke: var(--snackbar-color);
}

.wrapper.warning .icon,
.wrapper.success .icon {
  top: 50%;
  transform: translateY(-50%);
}

.successIcon {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-50%);
}

.successIcon path {
  fill: #00c029;
}

.text {
  color: var(--snackbar-color);
}
