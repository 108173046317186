.separator {
  filter: brightness(0.9);
  width: 1px;
  background-color: var(--theme_color);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.buttonGroup {
  display: flex;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.buttonGroup button {
  border: 0;
  border-radius: 0;
}

.buttonGroup-shape-ellipse {
  border-radius: 50px;
}

.buttonGroup-ellipse {
  border-radius: 50px;
}

.buttonGroup-type-grey {
  border: 1px solid var(--color_grey300);
}

.buttonGroup-type-primary {
  background-color: var(--theme_color);
}

.buttonGroup-type-grey .separator {
  filter: none;
  background-color: var(--color_grey300);
}

.buttonGroup-disabled {
  background: var(--disabled_default_color);
  border-color: var(--disabled_default_color);
}

.buttonGroup-disabled .separator {
  filter: brightness(1.45);
  background-color: var(--disabled_text_color);
}
