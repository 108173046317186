.closeButton {
  margin: 18px 24px;
}

@media (max-width: 768px) {
  .closeButton {
    margin: 19px 24px;
  }

  .closeButton svg {
    width: 2.8rem;
    height: 2.8rem;
  }
}

.modalWrapper {
  padding: 0;
  width: 570px;
  font-family: var(--font_family-accent);
  letter-spacing: 0.2px;
}

.wrapper {
  z-index: 1305;
}

.modalHeader {
  padding: 18px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23.4px;
  border-bottom: 1px solid #eee;
}

.modalBody {
  padding: 24px;
  font-size: 16px;
  line-height: 20px;
}

.alertBlock {
  margin-top: 12px;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.modalFooter {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 12px 16px;
  border-top: 1px solid #eee;
}

.modalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: auto;
  max-width: 80px;
  height: 35px;
  font-size: 16px;
  letter-spacing: 0.2px;
}

:global(.buttons-wider) .modalButton {
  padding-right: 14px;
  padding-left: 14px;
  max-width: none;
}
