.container {
  overflow: auto;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .container {
    /* flex-direction: column;
    flex-wrap: nowrap; */
    margin: 0;
  }
}
