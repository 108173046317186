.image {
  --size: 1.7rem;

  position: relative;
  max-width: 100%;
}

.point {
  --m: calc(var(--size) / -2);

  position: absolute;
  opacity: 0.6;
  box-sizing: border-box;
  margin-top: var(--m);
  margin-left: var(--m);
  width: var(--size);
  height: var(--size);
  background: #dadada;
  border: 1px solid #fff;
  border-radius: 50%;
}

.popup {
  --w: 25rem;

  position: absolute;
  margin-top: 1.7rem;
  margin-left: calc(var(--w) / -2 - 0.5rem);
  width: var(--w);
}

.text {
  position: absolute;
  top: 2.9rem;
  left: 2.6rem;
  width: calc(var(--w) - 5rem);
  font-weight: normal;
  font-style: normal;
  font-size: 1.1rem;
  font-family: var(--font_family-accent);
  line-height: 1.2rem;
  letter-spacing: 0.0651429rem;
  color: #3e3e40;
}
