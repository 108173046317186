.icon {
  display: inline-block;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  font-style: normal;
  line-height: 0;
  color: inherit;
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon > * {
  line-height: 1;
}

.icon svg {
  cursor: inherit !important;
  display: inline-block;
  transition: all 0.2s ease;
}

.icon::before {
  display: none;
}

.icon .icon-icon {
  display: block;
}

.icon-clickable {
  cursor: pointer;
}

.icon-clickable svg {
  fill: #3f3c43;
}

.icon-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.icon-hide {
  opacity: 0.3;
}

.icon-spin::before,
.icon-spin {
  display: inline-block;
  -webkit-animation: loadingcircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.icon-spin-reverse::before,
.icon-spin-reverse {
  display: inline-block;
  animation: loadingCircleReverse 1s infinite linear;
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircleReverse {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
