.imageContainer {
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}

.imageContainer img {
  user-select: none;
  display: block;
  object-fit: contain;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.hover {
  --padding-size: 10px;

  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding: var(--padding-size);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
}

.hover:hover {
  opacity: 1;
}

.delete {
  position: absolute;
  right: var(--padding-size);
}

.edit svg {
  width: 2.4rem;
  height: 2.4rem;
}

.slider {
  position: absolute;
  top: 40%;
  right: 10%;
  width: 80%;
}

@media (hover: none), screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hover {
    opacity: 1;
    background: initial;
  }

  .edit,
  .delete {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.7);
  }

  .edit {
    left: 0;
  }

  .delete {
    right: 0;
  }
}

.imgBlock :global .ImageLoadingHandler {
  padding-bottom: 88%;
}

.frameWrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 88%;
  background-color: #fff;
}

.file {
  position: absolute;
  height: 100%;
}

.icon {
  position: relative;
  bottom: 0.2rem;
  left: 0.2rem;
  font-size: 2.4rem;
  color: #fff;
}
