.custom-main-boats .custom-landing-page-mobile-nav {
  display: none;
}

.custom-main-boats .custom-scroll-arrow:nth-of-type(2) {
  display: none !important;
}

.custom-main-boats .custom-landing-wrapper-cover_page {
  position: sticky;
  flex-direction: column-reverse;
  width: 100%;
}

.custom-main-boats .custom-start-arrow {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: transparent;
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-cover_page {
  position: static;
  height: 100vh;
}

.custom-modal-content .custom-main-boats .custom-landing-wrapper-cover_page .custom-cover_page {
  height: 100%;
}

.custom-modal-content .custom-main-boats .custom-landing-wrapper-cover_page .custom-right-content {
  padding-bottom: 162px;
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-cover_page-right {
  position: static;
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-cover_page-right::after {
  display: none;
}

.custom-main-boats.withActions .custom-landing-wrapper-cover_page .custom-cover_page {
  height: calc(100vh - 200px);
}

.custom-main-boats .custom-left-button {
  top: 65px;
  z-index: 5;
}

.custom-main-boats .custom-left-content {
  padding-top: 75px;
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-cover_page-left {
  width: 100%;
}

.custom-main-boats .custom-right-page-header {
  width: 100%;
}

.custom-main-boats.withActions .custom-right-header-logo {
  position: relative;
  left: -4rem;
}

.custom-main-boats .custom-right-header-actions {
  right: 12.5rem;
}

.custom-main-boats .custom-right-headline::before {
  height: 50px;
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-left-subheadline-border {
  display: none;
}

.custom-main-boats .custom-right-prepared-for {
  position: absolute;
  bottom: 15px;
  display: block;
  width: 100%;
  font-style: italic;
  font-size: var(--landingCoverSubheadlineFontSize);
  font-family: var(--font_family-serif);
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-left-subheadline {
  position: absolute;
  bottom: 75px;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  line-height: 27px;
  white-space: pre;
  color: var(--black_color);
}

.custom-main-boats .custom-landing-wrapper-cover_page .custom-left-traveldates {
  position: absolute;
  bottom: 48px;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  line-height: 27px;
  color: var(--black_color);
}

.custom-main-boats .header_menu_icon_wrapper {
  position: relative;
  z-index: 100;
}

.custom-main-boats .custom-landing-page-burger-header {
  position: relative;
  z-index: 5;
  margin-top: 6.5rem;
}

@media (max-width: 768px) {
  .custom-main-boats.withActions .custom-right-header-logo {
    left: 0;
  }

  .custom-main-boats .custom-landing-wrapper-cover_page .custom-cover_page {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0;
    height: auto;
  }

  .custom-main-boats.withActions .custom-landing-wrapper-cover_page .custom-cover_page {
    height: auto;
  }

  .custom-main-boats .custom-coverpage-wrapper {
    padding-bottom: 140px;
  }

  .custom-main-boats .custom-start-arrow {
    bottom: 25px;
  }

  .custom-main-boats.withActions .custom-start-arrow {
    bottom: 15px;
    left: 0;
  }

  .custom-main-boats .custom-landing-wrapper-cover_page::after {
    display: none;
  }

  .custom-main-boats .custom-cover_page-left .custom-start-arrow {
    display: none;
  }

  .custom-main-boats .custom-cover_page-left .custom-left-subheadline {
    display: none;
  }

  .custom-main-boats .custom-cover_page-left .custom-left-traveldates {
    display: none;
  }
}

@media (max-width: 468px) {
  .custom-main-boats .custom-landing-wrapper-cover_page .custom-left-subheadline {
    font-size: 1.8rem;
  }

  .custom-main-boats .custom-landing-wrapper-cover_page .custom-left-traveldates {
    font-size: 1.8rem;
  }
}
