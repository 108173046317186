.tag {
  display: inline-flex;
  margin-top: 1rem;
  background-color: #fff;
  border: 1px solid var(--input_border_color);
  border-radius: 0.5rem;
}

.tagPrimary {
  margin-top: 0;
  padding: 0.4rem 0.4rem 0.4rem 0.8rem;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0.4rem;
}

.title,
.handle {
  padding: 1.1rem;
}

.title {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.6rem;
  font-family: var(--font_family-accent);
  line-height: 1.8rem;
  word-break: break-word;
}

.titlePrimary {
  padding: 0;
  padding-right: 0.4rem;
  text-transform: none;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.handle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--input_border_color);
}

.handlePrimary {
  padding: 0;
  border-left: none;
}

.icon {
  vertical-align: top;
}

.icon > svg {
  vertical-align: top;
  stroke: var(--input_border_color);

  /* height: 1.7rem; */
}

.handle:hover .icon svg {
  stroke: #000;
}
