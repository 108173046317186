.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.timeWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 10px 0;
  height: 44px;
  font-size: 16px;
  line-height: 10px;
  color: #212121;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.timeWrapper:hover {
  border-color: #b3b3b3;
}

.timeWrapper label {
  position: absolute;
  top: 0;
  pointer-events: none;
  font-size: 16px;
  line-height: 1px;
  color: #9e9e9e;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, 22px);
  transform-origin: top left;
}

.input {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.timeWrapper:focus-within label,
.timeWrapper .filled {
  font-size: 11px;
  transform: translate(0, 13px);
}

.timeString {
  font-size: 16px;
}

.timePeriod {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: none;
  align-items: center;
  margin: auto 0;
  height: 100%;
}

.timeWrapper:focus-within .timePeriod {
  display: flex;
}

.timePeriodOptions {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 12px 10px 12px 6px;
  height: 100%;
  color: #9e9e9e;
  border-left: 1px solid #e0e0e0;
}

.activeOption {
  font-weight: 600;
  color: #3f3c43;
}

.clearButton {
  padding-right: 8px;
}

.errorBorder {
  border: 1px solid #d72c0d;
}

@media screen and (max-width: 768px) {
  .timeWrapper {
    padding: 1rem 1.2rem 0.2rem;
    height: 4.4rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .timeWrapper > * {
    font-size: 1.6rem;
  }

  .timeWrapper label {
    font-size: 1.6rem;
    transform: translate(0, 1.8rem);
  }

  .timeWrapper:focus-within label,
  .timeWrapper .filled {
    font-size: 1.1rem;
    line-height: 1rem;
    transform: translate(0, 0.4rem);
  }
}
