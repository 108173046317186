.formGroup {
  margin: 0 0 2rem;
}

.formGroup .formGroup {
  margin: 0;
}

.formGroup-checkboxGroup,
.formGroup-radioButtonGroup {
  margin-bottom: 0.2rem;
}

.formGroup-noMargin {
  margin-bottom: 0;
}

.formGroup-inline {
  display: inline-block;
}

.formLabel {
  display: block;
  padding: 0 0 1.4rem 0;
}

.formLabel.zeroPadding {
  padding: 0;
}

.formLabelDescription {
  display: block;
  padding: 0 0 1.4rem 0;
  font-size: var(--font_size-base) !important;
}

.formError {
  display: block;
  padding: 0.25rem 0 0 1.7rem;
  line-height: 1.5rem;
}

.formGroup-checkboxGroup .inputError {
  padding: 0;
}

.formHelp {
  opacity: 0.85;
  padding: 0.25rem 0 0 1.7rem;
}

.password {
  position: absolute;
}

.popup:empty {
  display: none;
}
