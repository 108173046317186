.checkboxWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 0 0 3rem;
  min-height: 2rem;
}

.checkboxWrapper-noMargin {
  margin-bottom: 0;
}

.checkboxWrapper-sm {
  padding: 0 0 0 2.5rem;
  min-height: 1.7rem;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border: 1px solid var(--input_border_color);
  border-radius: 0.3rem;
  transition: all 0.3s;
}

.checkbox-primary {
  background-color: #fff;
  border: 2px solid var(--input_border_color);
  border-radius: 0.5rem;
}

.checkbox-y-centered {
  top: calc(50% - 2rem / 2);
}

.checkboxWrapper-sm .checkbox {
  width: 1.7rem;
  min-width: 1.7rem;
  height: 1.7rem;
}

.checkboxWrapper-sm .checkbox-y-centered {
  top: calc(50% - 1.7rem / 2);
}

.checkbox::after {
  content: '';
  display: none;
  margin-top: -2px;
  width: 0.4rem;
  height: 1rem;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(35deg);
}

.checkboxTitle {
  font-size: 1.4rem;
  font-family: var(--font_family-accent);
  line-height: 2rem;
  color: var(--text_color);
  transition: all 0.35s;
}

.checkboxWrapper-disabled .checkboxTitle {
  color: var(--input_placeholder_color);
}

.hiddenBaseCheckboxButton {
  position: absolute;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}

.checkbox:hover {
  background-color: var(--theme_color);
  border-color: var(--theme_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox {
  background-color: var(--theme_color);
  border-color: var(--theme_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox::after {
  display: block;
  border-right-color: #fff;
  border-bottom-color: #fff;
}

.checkbox-secondary:hover {
  background-color: var(--secondary_color);
  border-color: var(--secondary_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox-secondary {
  background-color: var(--secondary_color);
  border-color: var(--secondary_color);
}

.checkbox-secondary2:hover {
  background-color: var(--secondary2_color);
  border-color: var(--secondary2_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox-secondary2 {
  background-color: var(--secondary2_color);
  border-color: var(--secondary2_color);
}

.checkbox-danger:hover {
  background-color: var(--danger_color);
  border-color: var(--danger_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox-danger {
  background-color: var(--danger_color);
  border-color: var(--danger_color);
}

.checkbox-success:hover {
  background-color: var(--success_color);
  border-color: var(--success_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox-success {
  background-color: var(--success_color);
  border-color: var(--success_color);
}

.checkbox-warning:hover {
  background-color: var(--warning_color);
  border-color: var(--warning_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox-warning {
  background-color: var(--warning_color);
  border-color: var(--warning_color);
}

.checkbox-accent:hover {
  background-color: var(--accent_color);
  border-color: var(--accent_color);
}

.hiddenBaseCheckboxButton:checked + .checkbox-accent {
  background-color: var(--accent_color);
  border-color: var(--accent_color);
}

.checkbox-disabled,
.checkbox-disabled:hover {
  background-color: var(--input_placeholder_color) !important;
  border-color: var(--input_placeholder_color) !important;
}

.checkboxTitle-smText {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.checkboxTitle-mediumText {
  font-size: 1.7rem;
  line-height: 2rem;
}
