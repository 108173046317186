.button {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  padding: 1rem 3.6rem;
  min-width: 10.4rem;
  min-height: 2.6rem;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.6rem;
  font-family: var(--font_family-accent);
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.3px;
  color: #fff;
  background-color: var(--theme_color);
  border: 0.1rem solid var(--theme_color);
  border-radius: 0.4rem;
  outline: 0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .button {
      margin: 0;
    }
  }
}

.button::before {
  content: '';
  position: absolute;
  top: -0.1rem;
  right: -0.1rem;
  bottom: -0.1rem;
  left: -0.1rem;
  z-index: 1;
  opacity: 0.35;
  pointer-events: none;
  display: none;
  background: #fff;
  border-radius: inherit;
  transition: opacity 0.2s;
}

.button::after {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  display: none;
  border: 1px solid var(--theme_color);
  border-radius: 5px;
}

.button:hover {
  color: #fff;
  background-color: var(--theme_color-darken);
  border-color: var(--theme_color-darken);
}

.button:focus {
  color: #fff;
  background-color: var(--theme_color);
}

.button:focus::after {
  display: block;
  border: 0.15rem solid #fff;
  box-shadow: 0 0 1px var(--theme_color);
}

.button:active {
  color: #fff;
  background-color: var(--theme_color-darken);
}

.button-block {
  width: 100%;
}

.button-default {
  color: #3f3c43;
  background-color: #fff;
  border: 1px solid var(--color_grey400);
}

.button-default:hover {
  color: #3f3c43;
  background: var(--disabled_default_color);
  border: 1px solid #9e9e9e;
}

.button-default::after {
  border-color: #d0d0d0;
}

.button-default:hover::after {
  border-color: #d0d0d0;
}

.button-default:active {
  background-color: #bebfbf;
}

.button-default:active::after {
  display: none;
}

.button-default:focus {
  color: var(--gray_color-darken);
  background-color: #fafafa;
  border-color: #fafafa;
}

.button-default:focus::after {
  display: block;
  border: 0.15rem solid #fff;
  box-shadow: 0 0 1px var(--gray_color-darken);
}

.button-primary2 {
  color: var(--primary_color);
  background-color: #fff;
  border: 0.1rem solid var(--primary_color);
}

.button-primary2:hover {
  color: var(--primary_color-saturated);
  background: var(--disabled_default_color);
  border: 1px solid var(--primary_color-saturated);
}

.button-primary2:focus {
  color: var(--primary_color);
  background-color: #fff;
}

.button-primary2:focus:hover {
  color: var(--primary_color-saturated);
  background: var(--disabled_default_color);
  border: 1px solid var(--primary_color-saturated);
}

.button-primary2:focus::after {
  display: none;
}

.button-secondary {
  color: #1624c2;
  background-color: var(--secondary_color);
  border-color: var(--secondary_color);
}

.button-secondary:hover {
  color: #1624c2;
  background-color: #fff;
  border-color: var(--secondary_color-lighten);
}

.button-secondary:focus {
  color: #1624c2;
  background-color: var(--secondary_color);
}

.button-secondary:focus::after {
  border: 1px solid var(--secondary_color);
}

.button-secondary:active {
  color: #1624c2;
  background-color: var(--secondary_color-darken);
}

.button-grey {
  color: #3f3c43;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button-grey:hover {
  color: #3f3c43;
  background-color: #eee;
  border-color: #eee;
}

.button-grey:focus {
  color: #3f3c43;
  background-color: #fafafa;
  border-color: #fff;
}

.button-grey:focus::after {
  display: block;
  border: 0.15rem solid #fff;
  box-shadow: 0 0 1px var(--gray_color-darken);
}

.button-grey:disabled {
  color: #8c9196;
  background-color: #e0e0e0;
}

.button-dark-grey {
  color: #3f3c43;
  background-color: #e0e0e0;
  border: 1px solid #bdbdbd;
}

.button-dark-grey:hover {
  color: #3f3c43;
  background-color: #e0e0e0;
  border: 1px solid #bdbdbd;
}

.button-dark-grey:focus {
  color: #3f3c43;
  background-color: #e0e0e0;
  border: 1px solid #bdbdbd;
}

.button-dark-grey:disabled {
  background-color: #e0e0e0;
  border: 1px solid #bdbdbd;
}

.button-text {
  color: #3f3c43;
  background-color: transparent;
  border: none;
}

.button-text:hover {
  color: #3f3c43;
  background-color: #f5f5f5;
  border: none;
}

.button-text:focus {
  color: #3f3c43;
  background-color: #f5f5f5;
  border: none;
}

.button-text:focus::after {
  display: none;
  border: none;
}

.button-text:disabled {
  color: #8c9196;
  background-color: transparent;
}

.button-danger {
  color: #fff;
  background-color: var(--danger_color);
  border-color: var(--danger_color);
}

.button-danger:focus {
  color: #fff;
  background-color: var(--danger_color);
}

.button-danger:focus::after {
  display: block;
  border: 0.15rem solid #fff !important;
  box-shadow: 0 0 1px var(--danger_color);
}

.button-danger:hover {
  color: #fff;
  background-color: var(--danger_color-saturated);
  border-color: var(--danger_color-saturated);
}

.button-danger:active {
  color: #fff;
  background-color: var(--danger_color-darken);
  border-color: var(--danger_color-darken);
}

.button-success {
  color: #fff;
  background-color: var(--success_color);
  border-color: var(--success_color);
}

.button-success:focus {
  color: #fff;
  background-color: var(--success_color);
}

.button-success:focus::after {
  display: block;
  border: 0.15rem solid #fff !important;
  box-shadow: 0 0 1px var(--success_color);
}

.button-success:hover {
  color: #fff;
  background-color: var(--success_color-saturated);
  border-color: var(--success_color-saturated);
}

.button-success:active {
  color: #fff;
  background-color: var(--success_color-saturated);
  border-color: var(--success_color-saturated);
}

.button-warning {
  color: #fff;
  background-color: var(--warning_color);
  border-color: var(--warning_color);
}

.button-warning:focus {
  color: #fff;
  background-color: var(--warning_color);
}

.button-warning:focus::after {
  border: 1px solid var(--warning_color);
}

.button-warning:hover {
  color: var(--warning_color);
  background-color: #fff;
  border-color: var(--warning_color);
}

.button-warning:active {
  color: #fff;
  background-color: var(--warning_color-darken);
  border-color: var(--warning_color-darken);
}

.button-accent {
  color: #fff;
  background-color: var(--accent_color);
  border-color: var(--accent_color);
}

.button-accent:focus {
  color: #fff;
  background-color: var(--accent_color);
}

.button-accent:focus::after {
  border: 1px solid var(--accent_color);
}

.button-accent:hover {
  color: var(--accent_color);
  background-color: #fff;
  border-color: var(--accent_color);
}

.button-accent:active {
  color: #fff;
  background-color: var(--accent_color-darken);
  border-color: var(--accent_color-darken);
}

.button-secondary2 {
  color: #fff;
  background-color: var(--secondary2_color);
  border-color: var(--secondary2_color);
}

.button-secondary2:focus {
  color: #fff;
  background-color: var(--secondary2_color-saturated);
}

.button-secondary2:focus::after {
  border: 0.15rem solid #fff !important;
  box-shadow: 0 0 0.1rem var(--secondary2_color);
}

.button-secondary2:hover {
  color: #fff;
  background-color: var(--secondary2_color-saturated);
  border-color: var(--secondary2_color-saturated);
}

.button-secondary2:active {
  color: #fff;
  background-color: var(--secondary2_color-saturated);
  border-color: var(--secondary2_color-saturated);
}

.button-black {
  color: #fff;
  background-color: var(--black_color);
  border-color: var(--black_color);
}

.button-black:hover {
  color: var(--black_color);
  background-color: #fff;
  border-color: var(--black_color-lighten);
}

.button-black:focus {
  color: #fff;
  background-color: var(--black_color);
}

.button-black:focus::after {
  border: 1px solid var(--black_color);
}

.button-black:active {
  color: #fff;
  background-color: var(--black_color-darken);
}

.button-facebook {
  color: #fff;
  background-color: var(--facebook_color);
  border-color: var(--facebook_color);
}

.button-facebook:hover {
  color: var(--facebook_color);
  background-color: #fff;
  border-color: var(--facebook_color-lighten);
}

.button-facebook:focus {
  color: #fff;
  background-color: var(--facebook_color);
}

.button-facebook:focus::after {
  border: 1px solid var(--facebook_color);
}

.button-facebook:active {
  color: #fff;
  background-color: var(--facebook_color-darken);
}

.button-twitter {
  color: #fff;
  background-color: var(--twitter_color);
  border-color: var(--twitter_color);
}

.button-twitter:hover {
  color: var(--twitter_color);
  background-color: #fff;
  border-color: var(--twitter_color-lighten);
}

.button-twitter:focus {
  color: #fff;
  background-color: var(--twitter_color);
}

.button-twitter:focus::after {
  border: 1px solid var(--twitter_color);
}

.button-twitter:active {
  color: #fff;
  background-color: var(--twitter_color-darken);
}

.button-empty {
  padding: 0;
  min-width: auto;
  min-height: auto;
  background-color: transparent;
  border: none;
  outline: none;
}

.button-empty:active,
.button-empty:hover,
.button-empty:focus {
  background-color: transparent !important;
  border: none !important;
  outline: none;
}

.button-empty:focus::after {
  display: none;
}

.button-link {
  color: var(--primary_color);
  background-color: transparent;
  border-color: transparent;
}

.button-loading {
  position: relative;
  cursor: wait;
}

.button-loading::before {
  display: block;
}

.button-disabled {
  cursor: not-allowed;
}

.button-disabled.button-default::after {
  display: none;
}

.button-disabled.button,
.button-disabled.button:hover,
.button-disabled.button:focus,
.button-disabled.button:active {
  color: var(--disabled_text_color);
  background: var(--disabled_color);
  border-color: var(--disabled_color);
}

.button-disabled.button-default,
.button-disabled.button-default:hover,
.button-disabled.button-default:focus,
.button-disabled.button-default:active {
  /* color: #fff; */
  color: #bdbdbd;

  /* color: #8C9196; */
  background-color: var(--disabled_default_color);

  /* border-color: var(--disabled_default_color); */
  border-color: #e0e0e0;
}

.button-disabled.button-primary2,
.button-disabled.button-primary2:hover,
.button-disabled.button-primary2:focus,
.button-disabled.button-primary2:active {
  color: #bdbdbd;
  background-color: #fff;
  border-color: #f5f5f5;
}

.button-disabled.button-danger,
.button-disabled.button-danger:hover,
.button-disabled.button-danger:focus,
.button-disabled.button-danger:active {
  color: #fff;
  background-color: var(--danger_color-lighten);
  border-color: var(--danger_color-lighten);
}

.button-disabled.button-secondary,
.button-disabled.button-secondary:hover,
.button-disabled.button-secondary:focus,
.button-disabled.button-secondary:active {
  color: #fff;
  background-color: var(--secondary_color-lighten);
  border-color: var(--secondary_color-lighten);
}

.button-disabled.button-success,
.button-disabled.button-success:hover,
.button-disabled.button-success:focus,
.button-disabled.button-success:active {
  color: #fff;
  background-color: var(--success_color-lighten);
  border-color: var(--success_color-lighten);
}

.button-disabled.button-warning,
.button-disabled.button-warning:hover,
.button-disabled.button-warning:focus,
.button-disabled.button-warning:active {
  color: #fff;
  background-color: var(--warning_color-lighten);
  border-color: var(--warning_color-lighten);
}

.button-disabled.button-accent,
.button-disabled.button-accent:hover,
.button-disabled.button-accent:focus,
.button-disabled.button-accent:active {
  color: #fff;
  background-color: var(--accent_color-lighten);
  border-color: var(--accent_color-lighten);
}

.button-disabled.button-black,
.button-disabled.button-black:hover,
.button-disabled.button-black:focus,
.button-disabled.button-black:active {
  color: #fff;
  background-color: var(--black_color-lighten);
  border-color: var(--black_color-lighten);
}

.button-disabled.button-facebook,
.button-disabled.button-facebook:hover,
.button-disabled.button-facebook:focus,
.button-disabled.button-facebook:active {
  color: #fff;
  background-color: var(--facebook_color-lighten);
  border-color: var(--facebook_color-lighten);
}

.button-disabled.button-twitter,
.button-disabled.button-twitter:hover,
.button-disabled.button-twitter:focus,
.button-disabled.button-twitter:active {
  color: #fff;
  background-color: var(--twitter_color-lighten);
  border-color: var(--twitter_color-lighten);
}

.button-undesirable {
  color: #c4c4c4;
  background-color: #fff;
  border: 1px solid #d0d0d0;
}

.button-medium {
  padding: 0.75rem 1.6rem;
  min-width: 8rem;
  min-height: 3.5rem;
  font-weight: 500;
}

.button-small,
.buttonGroup-small > .button {
  padding: 0.4rem 0.8rem;
  min-width: auto;
  min-height: 2.6rem;
  font-weight: 400;
  font-size: 1.4rem;
}

.button-extraSmall,
.buttonGroup-extraSmall > .button {
  padding: 0.6rem 1.2rem;
  min-width: auto;
  min-height: 2.1rem;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .button-small {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.button-small-long {
  padding: 0.4rem 2.3rem;
  min-width: auto;
  min-height: 2.6rem;
  font-size: 1.6rem;
}

@media (max-width: 480px) {
  .button-small-long {
    padding: 0.4rem 1rem;
  }
}

.button-large,
.buttonGroup-large > .button {
  padding: 1rem 1.2rem;
  min-height: 4.4rem;
  max-height: 5rem;
  font-weight: 600;
}

.button-circle {
  padding: 0;
  width: 3.2rem;
  height: 3.2rem;
  text-align: center;
  line-height: 3.2rem;
  border-radius: 50%;
}

.button-circle.button-large {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

.button-circle.button-small {
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
}

.button-ellipse,
.button-circle:focus::after,
.button-ellipse:focus::after {
  border-radius: 50px;
}

.buttonGroup-ellipse {
  border-radius: 50px;
}

.button-circle .button-circle:focus::after,
.button-ellipse:focus::after {
  box-shadow:
    0 0 0 2px #dcdcde,
    0 0 0 1px #fff;
}

.buttonGroup {
  position: relative;
  display: flex;
  overflow: auto;
}

.buttonGroup .button {
  border-radius: 0;
}

.buttonGroup > .button:only-child {
  border-radius: 4px;
}

.buttonGroup .button + .button {
  margin-left: -0.1rem;
}

.buttonGroup > .button:hover,
.buttonGroup > .button:focus,
.buttonGroup > .button:active {
  z-index: 2;
}

.buttonGroup > .button:first-child:not(:last-child) {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.buttonGroup > .button:last-child:not(:first-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.buttonGroup .button-danger + .button-danger,
.buttonGroup .button-danger:last-child:not(:first-child) {
  border-left-color: var(--danger_color-lighten);
}

.button > span + span {
  margin-left: 0.8rem;
}

.width-fit-conent {
  min-width: fit-content !important;
}
