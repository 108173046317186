.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  transition: all 0.25s ease;
}

.inputContainer-marginBottom {
  margin: 0 0 1.4rem;
}

.input {
  margin: 0;
  padding: 0.7rem 1.7rem;
  width: 100%;
  font-size: 1.7rem;
  font-family: var(--font_family-accent);
  letter-spacing: 0.2px;
  color: var(--text_color);
  border: 1px solid var(--input_border_color);
  border-radius: 0.5rem;
  outline: none;
  transition: all 0.25s ease;
}

.input::placeholder {
  text-overflow: ellipsis;
  color: var(--input_placeholder_color);
}

.input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-overflow: ellipsis;
}

.input::-moz-placeholder {
  /* Firefox 19+ */
  text-overflow: ellipsis;
}

.input:-ms-input-placeholder {
  /* IE 10+ */
  text-overflow: ellipsis;
}

.input:-moz-placeholder {
  /* Firefox 18- */
  text-overflow: ellipsis;
}

.input:placeholder-shown {
  text-overflow: ellipsis;
}

.input-withPrefix {
  padding-left: 5rem;
}

.input-withPostfix {
  padding-right: 5rem;
}

.prefix,
.postfix {
  position: absolute;
  top: 1px;
  bottom: 1px;
  opacity: 0.75;
  display: flex;
  align-items: center;
  font-size: var(--font_size-base);
  color: var(--input-secondary);
}

.prefix::after,
.postfix::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: var(--input_border_color);
}

.prefix {
  left: 1rem;
  margin-top: 0.2rem;
  margin-right: 1rem;
}

.prefix::after {
  right: -1rem;
}

.postfix {
  right: 1rem;
}

.postfix::after {
  left: -1rem;
}

.input:hover {
  border-color: var(--input_border_color-focus);
}

.input:focus {
  border-color: var(--input_border_color-focus);
  box-shadow: inset 0 0 5px #65adff;
}

.inputContainer-isError .input {
  border-color: var(--input_border_color-danger);
}

.inputContainer-isError .prefix,
.inputContainer-isError .postfix {
  color: var(--input_border_color-danger);
}

/* .inputContainer-isError .prefix::after,
.inputContainer-isError .postfix::after {
  background-color: var(--input_border_color-danger);
} */

.inputContainer-search .input {
  background: transparent;
}

@media (max-width: 768px) {
  .inputContainer-search .input {
    font-size: 14px;
  }

  .inputContainer-search svg {
    width: 16px;
    height: 16px;
  }

  .inputContainer-search .input::placeholder {
    font-size: 2.1rem !important;
  }
}

.inputContainer-search .input::placeholder {
  font-style: italic;
  font-size: 1.6rem;
  font-family: var(--font_family-base);
  color: #9e9e9e;
}

.inputContainer-search .prefix::after {
  display: none;
}

.inputContainer-search .input-withPrefix {
  padding-left: 3.8rem;
}

.inputContainer-disabled .input {
  cursor: not-allowed !important;
  color: var(--input_placeholder_color);
  background-color: #f3f3f3;
  border: none;
}

.inputContainer-search .input:focus {
  box-shadow: none;
}

.inputContainer-isError .input:hover,
.inputContainer-isError .input:focus {
  border-color: var(--input_border_color-danger);
}

.inputContainer-isError .input::placeholder {
  color: var(--input_placeholder_color);
}

.input-noBorder {
  border: none;
}

.inputContainer-noBorder .prefix {
  left: 0;
}

.inputContainer-search.inputContainer-noBorder .input-withPrefix {
  padding-left: 2.8rem;
}

.input-withBackground {
  background-color: #f3f3f3;
  border: none;
}

.input-readOnly {
  cursor: not-allowed;
}

.input-readOnly:hover,
.input-readOnly:focus {
  border-color: var(--input_border_color);
  box-shadow: none;
}

.input-startAdornment {
  padding-left: 50px;
}

.input-startAdornment:focus {
  box-shadow: none;
}

.startAdornment {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  width: 40px;
  border-right: 1px solid var(--input_border_color);
  border-radius: 0.5rem;
}

.startAdornment > * {
  border-radius: inherit;
}

.copyBtn {
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 1;
  margin-top: -13px;
  padding: 7px 29px;
  min-width: auto;
  height: 26px;
  min-height: auto;
  font-size: 10px;
}
