.inputContainer {
  position: relative;
}

.input {
  padding-right: 3.6rem;
}

.iconBtn {
  position: absolute;
  top: 50%;
  right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1rem;
  padding: 0;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: none;
  outline: none;
}

.icon {
  position: relative;
}

.icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0.9rem;
  opacity: 0;
  width: 0.1rem;
  height: 100%;
  background-color: var(--gray_color-darken);
  transition: all 0.3s ease;
  transform: rotate(45deg);
}

.icon svg {
  fill: var(--gray_color-darken);
  transition: all 0.3s ease;
}

.iconBtn:hover .icon svg {
  fill: var(--primary_color);
}

.iconBtn:hover .icon::after {
  background-color: var(--primary_color);
}

.iconActive::after {
  opacity: 1;
}

.position {
  position: relative;
  right: 3rem;
  bottom: 29rem;
}

@media (max-width: 768px) {
  .position {
    position: relative;
    top: 1rem;
    left: 0;
  }
}
