@font-face {
  font-weight: 300;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_light.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 300;
  font-style: italic;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_light_it.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_reg.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_reg_it.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 600;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_bld.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_bld_it.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 900;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_blk.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 900;
  font-style: italic;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_blk_it.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 500;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_med.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: 'Brandon';
  src: url('../assets/fonts/Brandon_med_it.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: normal;
  font-family: 'Hoefler';
  src: url('../assets/fonts/HoeflerText-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: bold;
  font-family: 'Hoefler';
  src: url('../assets/fonts/HoeflerText-Black.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: bold;
  font-style: italic;
  font-family: 'Hoefler';
  src: url('../assets/fonts/HoeflerText-BlackItalic.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: normal;
  font-style: italic;
  font-family: 'Hoefler';
  src: url('../assets/fonts/HoeflerText-Italic.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 300;
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Light.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 600;
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Semi-bold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Univers';
  src: url('../assets/fonts/UniversRegular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-family: 'Univers';
  src: url('../assets/fonts/UniversBold.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Apercu';
  src: url('../assets/fonts/ApercuRegular.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-family: 'Apercu';
  src: url('../assets/fonts/ApercuBold.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'BadScript';
  src: url('../assets/fonts/BadScriptRegular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Ubuntu';
  src: url('../assets/fonts/UbuntuRegular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-family: 'Ubuntu';
  src: url('../assets/fonts/UbuntuBold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-family: 'Adobe Garamond Pro';
  src: url('../assets/fonts/jbd/AGaramondPro-Bold.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Adobe Garamond Pro';
  src: url('../assets/fonts/jbd/AGaramondPro-Regular.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-family: 'Euclid Triangle';
  src: url('../assets/fonts/jbd/EuclidTriangle-Bold.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 300;
  font-family: 'Euclid Triangle';
  src: url('../assets/fonts/jbd/EuclidTriangle-Light.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 500;
  font-family: 'Euclid Triangle';
  src: url('../assets/fonts/jbd/EuclidTriangle-Medium.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'Euclid Triangle';
  src: url('../assets/fonts/jbd/EuclidTriangle-Regular.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-family: 'GT Sectra';
  src: url('../assets/fonts/jbd/GTSectra-Bold.otf') format('truetype');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-family: 'GT Sectra';
  src: url('../assets/fonts/jbd/GTSectra-Regular.otf') format('truetype');
  font-display: block;
}
